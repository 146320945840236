
import MainTable from "@/modules/payments/components/MainTable.vue";
import SearchForm from "@/modules/payments/components/SearchForm.vue";

export default {
    components: {SearchForm, MainTable},
    setup() {
        const tableName = 'payments'

        return {
            tableName
        }
    }
}
