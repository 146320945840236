<template>
    <div class="xcontainer">
        <div>
            <div class="align-items-center d-flex div-title-card justify-content-between row">
                <div class="align-items-baseline d-sm-flex flex-md-row flex-sm-column">
                    <h5 class="xtitle-buscar">Buscar pagos de compras</h5>
                    <p class="ml-md-3 ml-sm-0 pt-md-0 pt-sm-1 xsubtitle-buscar">(Búsqueda avanzada)</p>
                </div>
                <a href="#" @click.prevent="togglePanel">
                    <i class="fa"
                       :class="{'fa-chevron-down': !showPanel, 'fa-chevron-up': showPanel}"></i></a>
            </div>
            <div class="des01 m-3 pb-3" :class="{collapse: !showPanel}">
                <div class="pb-1 pl-3 pr-3 pt-2 row">
                    <div class="col-lg-3 col-md-3 col-sm-12">
                        <label>Nro. de pago</label>
                        <input type="text" class="form-control inp-filter" v-model="code">
                    </div>
                </div>
                <div class="row pt-3">
                    <div
                        class="col-lg-3 col-md-4 col-sm-12 d-flex justify-content-center offset-lg-8 offset-md-7 offset-sm-0">
                        <button type="button" class="btn btn-blue-deg btn-sm mr-1 mr-lg-5"
                                @click.prevent="search">Buscar
                        </button>
                        <button type="button" class="btn btn-outline-secondary btn-sm mr-0 pl-3 pr-3 limpia"
                                @click.prevent="clean">Limpiar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useAuth from "@/modules/auth/composables/useAuth";
import {ref} from "vue";
import usePanel from "@/composables/usePanel";

export default {
    props: ['tableName'],
    setup(props) {
        const code = ref('')
        const {companyId} = useAuth();
        const {showPanel, togglePanel} = usePanel(false)

        function search() {
            const list = window.$('body').find("#list" + props.tableName);

            list.setGridParam({postData: null});
            list.setGridParam({
                postData: {
                    filters: [
                        {field: 'companyId', value: companyId.value},
                        {field: 'code', value: code.value},
                    ]
                }
            });
            list.trigger('reloadGrid');
        }

        async function clean() {
            code.value = ''
            search()
        }

        return {
            togglePanel,
            showPanel,
            code,
            search,
            clean
        }
    }
}
</script>
